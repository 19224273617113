<template>
  <div>
    <van-popup
      v-model="localShow"
      position="bottom"
      closeable
      custom-style=""
      :close-on-click-overlay="false"
      @click-close-icon="onClose"
    >
      <div class="dateBox">
        <div class="dateBox_tit">时间范围</div>
        <div class="entry_tit">
          <span
            :class="[
              'entry_tit0',
              'entry_tit_1',
              unCommonDateFlag == '1' ? 'classToday' : '',
            ]"
            @click="clickThreeDay"
            >最近三天</span
          >
          <span
            :class="[
              'entry_tit0',
              'entry_tit_2',
              unCommonDateFlag == '2' ? 'classToday' : '',
            ]"
            @click="clickAMonth"
            >最近一个月</span
          >
          <span
            v-if="!hideThreeBtn"
            :class="[
              'entry_tit0',
              'entry_tit_3',
              unCommonDateFlag == '3' ? 'classToday' : '',
            ]"
            @click="clickThreeMonth"
            >最近三个月</span
          >
        </div>
        <van-divider />
        <!-- tab 设置两个时间组件 -->
        <div class="eee">
          <van-tabs v-if="showDate" :active="activeDate" @change="onChangeDate">
            <van-tab :title="currentDateStartFormat">
              <van-datetime-picker
                type="date"
                visible-item-count="3"
                v-model="currentDateStart"
                :max-date="maxStartDate"
                :formatter="formatter"
                title="选择开始日期"
                confirm-button-text=""
                cancel-button-text=""
                @confirm="startConfirm"
                @change="startChange"
                :show-toolbar="false"
              />
            </van-tab>
            <van-tab :title="currentDateEndFormat">
              <van-datetime-picker
                type="date"
                visible-item-count="3"
                v-model="currentDateEnd"
                :max-date="maxDate"
                :min-date="currentDateStart"
                :formatter="formatter"
                title="选择结束日期"
                confirm-button-text=""
                cancel-button-text=""
                @confirm="endConfirm"
                @change="endChange"
                :show-toolbar="false"
              />
            </van-tab>
          </van-tabs>
        </div>
        <div class="doneBtn">
          <van-button
            class="doneBtnClick"
            size="normal"
            @click="done_confirm"
            hairline
            color="#4470FC"
            >完成</van-button
          >
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import * as common from '../api/common'
import * as util from '../api/util'
import moment from 'moment'
export default {
  props: ['showDate', 'hideThreeBtn'],
  data () {
    return {
      activeDate: 0,
      tab_tit1: '开始日期',
      tab_tit2: '结束日期',
      currentDateStart: new Date(new Date().setMonth(new Date().getMonth() - 1) + 24 * 60 * 60 * 1000),
      currentDateEnd: new Date(),
      currentDateStartFormat: '',
      currentDateEndFormat: '',
      unCommonDateFormat: '', // 今天 明天 后天 快捷展示
      unCommonDateFlag: '', // 今天 明天 后天 快捷展示标识 1今天 2明天 3后天
      maxDate: new Date(),
      // minDate: new Date(),
      formatter (type, value) {
        if (type === 'year') {
          return `${value}年`
        }
        if (type === 'month') {
          return `${value}月`
        }
        if (type === 'day') {
          return `${value}日`
        }
        return value
      },
      recentStartDates: '', // 记录快捷方式开始日期
      recentEndDates: '', // 记录快捷方式结束日期
      localShow: this.showDate
    }
  },
  mounted () {
    this.currentDateStartFormat = common.fmtDate(this.currentDateStart, 'yyyy-MM-dd')
    this.currentDateEndFormat = common.fmtDate(this.currentDateEnd, 'yyyy-MM-dd')
  },
  computed: {
    maxStartDate () {
      // return this.currentDateEnd ? this.currentDateEnd : this.maxDate
      return this.maxDate
      // return this.currentDateEnd
    }

  },
  watch: {
    showDate (newVal) {
      // 监听 prop 的变化，并将其赋值给本地的 data 属性
      this.localShow = newVal
    }
  },
  methods: {
    onClose () {
      this.$emit('get-on-close')
    },
    onChangeDate () {

    },
    startConfirm (e) {
      // console.log('当前开始日期的value 点击确认', common.fmtDate(e, 'yyyy-MM-dd 00:00:00'))
    },
    // startInput (e) {
    //   console.log('正常选择日期startInput', common.fmtDate(e, 'yyyy-MM-dd 00:00:00')) // 标准时间
    // },
    endConfirm (e) {
      // console.log('正常选择日期endConfirm', common.fmtDate(e, 'yyyy-MM-dd 23:59:59')) // 标准时间
    },
    // endInput (e) {
    //   console.log('正常选择日期endInput', common.fmtDate(e, 'yyyy-MM-dd 23:59:59')) // 标准时间
    // },
    endChange () {
      // console.log('结束时间在上下滑动', common.fmtDate(this.currentDateEnd, 'yyyy-MM-dd 23:59:59'))
      this.currentDateEndFormat = common.fmtDate(this.currentDateEnd, 'yyyy-MM-dd')
      this.isShortcut(this.currentDateEndFormat, 2)
    },
    startChange () {
      // console.log('开始时间在上下滑动', common.fmtDate(this.currentDateStart, 'yyyy-MM-dd 00:00:00'))
      this.currentDateStartFormat = common.fmtDate(this.currentDateStart, 'yyyy-MM-dd')
      // console.log('dateArr1', this.recentStartDates)
      // console.log('dateArr2', this.recentEndDates)

      this.isShortcut(this.currentDateStartFormat, 1)
    },
    isShortcut (date, type) {
      if (type === 1) {
        if (date !== this.recentStartDates) {
        // 说明开始时间或者结束日期已经不符合快捷方式
          this.unCommonDateFlag = ''
          this.unCommonDateFormat = ''
        } else {

        }
      } else {
        if (date !== this.recentEndDates) {
        // 说明开始时间或者结束日期已经不符合快捷方式
          this.unCommonDateFlag = ''
          this.unCommonDateFormat = ''
        }
      }
    },
    done_confirm () {
      const beginDate = common.fmtDate(this.currentDateStart, 'yyyy-MM-dd')
      const endDate = common.fmtDate(this.currentDateEnd, 'yyyy-MM-dd')
      this.$emit('doneHaveSelDate', beginDate, endDate)
    },

    clickThreeDay () {
      const recentStartDates = this.getRecentDay(3)
      // console.log('点击了最近三天', recentStartDates)
      this.unCommonDateFlag = '1'
      this.recentStartDates = recentStartDates
      this.recentQuickCommon()
    },
    clickAMonth () {
      const recentStartDates = this.getRecentMonth(1)
      // console.log('点击了最近一个月', recentStartDates)
      this.unCommonDateFlag = '2'
      this.recentStartDates = recentStartDates
      this.recentQuickCommon()
    },
    clickThreeMonth () {
      const recentStartDates = this.getRecentMonth(3)
      // console.log('点击了最近3个月', recentStartDates)
      this.unCommonDateFlag = '3'
      this.recentStartDates = recentStartDates
      this.recentQuickCommon()
    },
    // 快捷按钮的common实现方法
    // h5 时间格式为New date()格式；微信小程序为getTime()
    recentQuickCommon () {
      // 整理赋值给选择框选中值
      var strtime = this.recentStartDates
      var dateS = new Date(strtime.replace(/-/g, '/'))
      // var timeS = dateS.getTime()
      // var timeE = new Date().getTime()
      // console.log('timeE', timeE)
      // console.log('再次转化的时间戳time1', timeS)

      this.currentDateStart = dateS
      this.currentDateEnd = new Date()
      this.currentDateStartFormat = this.recentStartDates
      this.currentDateEndFormat = common.fmtDate(new Date(), 'yyyy-MM-dd')
      this.recentEndDates = this.currentDateEndFormat
    },
    // 近N天 -Moment.js
    getRecentDay (n) {
      const day = moment(new Date())
        .subtract(n - 1, 'days')
        .format('YYYY-MM-DD')
      // console.log(day)
      return day
    },
    // 近N月 - Moment.js
    getRecentMonth (n) {
      // const month = moment(new Date())
      //   .subtract(n, 'months')
      //   .format('YYYY-MM-DD')
      // console.log('获取仅几个月前的日期', n, month)
      // return month
      const startOfRange = moment().subtract(n, 'months').add(1, 'days').format('YYYY-MM-DD')
      console.log(`获取最近 ${n} 个月的开始日期`, startOfRange)
      return startOfRange
    }

  }
}
</script>
<style lang="scss" scoped>
/* 日期弹出层 */
.dateBox_tit {
}
.dateBox_tit,
.entry_tit {
  padding: 0.1rem 0.15rem;
  text-align: left;
  display: flex;
}

.dateBox_tit,
.entry_tit0 {
  color: #4470fc;
  font-size: 0.14rem;
}

.entry_tit0 {
  border: 0.01rem solid #4470fc;
  padding: 0.04rem 0.12rem;
  margin-right: 0.12rem;
}

.doneBtn {
  display: flex;
  justify-content: space-between;
  margin: 0.08rem;
}
.doneBtnClick {
  flex-grow: 1;
  padding: 0.04rem 0.12rem;
  margin: 0 0.04rem;
}

.classToday {
  background-color: #4470fc;
  color: #fff;
}

.paginaBox {
  margin-bottom: 0.16rem;
}
</style>
